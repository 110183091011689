.breadcrumb {
  margin-bottom: 0; }

h1 {
  margin-top: 0; }

.accountNav a {
  text-align: left; }
  .accountNav a svg {
    margin-right: 20px; }

.borderless thead tr th {
  border-top: none !important;
  padding: 1px; }

.borderless tbody tr td {
  border-top: none !important;
  padding: 1px; }

.bg-colors-1 {
  background-image: linear-gradient(to right, #0061A0 40%, #d0dde1 40%); }
  .bg-colors-1 h1, .bg-colors-1 h2 {
    color: #444; }
  .bg-colors-1 .bg-colors-sidebar {
    background-color: #0061A0;
    min-height: 500px; }
    .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav {
      list-style: none;
      margin: 0;
      padding: 0; }
      .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav li {
        color: #FFF;
        font-size: 1.05em;
        list-style: none;
        padding: 0.5em 0 0.5em 1em;
        font-weight: normal;
        cursor: pointer; }
        .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav li.active {
          background-color: #ED8C01 !important; }
        .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav li a {
          color: #FFF;
          display: block; }
      .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav .sidebar-links {
        padding-top: 7px; }
        .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav .sidebar-links li {
          user-select: none; }
          .bg-colors-1 .bg-colors-sidebar ul.sidebar-nav .sidebar-links li:hover {
            background-color: #3381bd; }
  .bg-colors-1 .bg-colors-main {
    background-color: #d0dde1;
    min-height: 500px; }
    .bg-colors-1 .bg-colors-main #action-bar {
      background-color: #f2f6f7;
      min-height: 60px;
      margin: 0 -30px 0 -15px;
      padding: 15px;
      overflow: auto; }
      .bg-colors-1 .bg-colors-main #action-bar .message {
        margin-right: 40px; }
    .bg-colors-1 .bg-colors-main #account-content {
      padding: 15px 0; }
      .bg-colors-1 .bg-colors-main #account-content #other-shipping {
        color: #3381bd; }
    .bg-colors-1 .bg-colors-main hr {
      border-color: #999; }

@media (max-width: 767px) {
  body .content {
    padding-top: 120px; }
  .bg-colors-1 .bg-colors-main #action-bar {
    height: auto;
    margin: 0; }
    .bg-colors-1 .bg-colors-main #action-bar .container-fluid {
      padding-left: 0;
      padding-right: 0; }
  .bg-colors-1 .bg-colors-main #account-content .margin-l-1 {
    margin-left: 0; }
  body .row-tall {
    margin-bottom: 0; } }

#companySearch {
  position: relative; }
  #companySearch .companyDropdown {
    position: absolute;
    background: #FFF;
    border: 1px solid #a0daff;
    border-top: 1px solid #EEE;
    width: 92%;
    padding: 10px;
    z-index: 999;
    margin-bottom: 15px;
    top: 33px; }

.frc h4.f-value {
  color: #4d4d4d; }

.req {
  font-size: 1.25em;
  font-weight: bold; }

tr.order-amounts td {
  border: none !important;
  padding-top: 0 !important; }

.order-totals {
  float: right;
  margin: 1em;
  text-align: right; }
  .order-totals td {
    padding-left: 1.25em; }

td .product-img {
  max-height: 150px;
  max-width: 80px;
  height: auto; }

.uomSelect ul.dropdown-menu {
  max-height: 15em;
  max-width: 100%;
  overflow: scroll; }

@media print {
  #action-bar {
    display: none !important; } }
