footer {
  padding: 25px 0 50px 0;
  color: #edf8ff; }
  footer .lead {
    font-size: 1.3em;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: solid 1px #007ac9; }
  footer a {
    color: #a0daff; }
  footer p {
    color: #FFF; }
  footer ul {
    list-style-type: none;
    padding: 0; }
    footer ul li {
      margin-bottom: 4px; }
      footer ul li a:hover {
        color: white; }
  footer .social-media a {
    padding: 7px 10px;
    color: white; }
  footer .social-media a:hover {
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
    color: #FFF; }
