.jumbotron {
  background: #0061A0;
  -webkit-box-shadow: inset 0 5px 5px #005287;
  -moz-box-shadow: inset 0 5px 5px #005287;
  box-shadow: inset 0 5px 5px #005287;
  color: #FFF; }
  .jumbotron h1 {
    -webkit-text-shadow: -5px 8px 4px #003c63;
    -moz-text-shadow: -5px 8px 4px #003c63;
    text-shadow: -5px 8px 4px #003c63;
    margin-bottom: 25px; }
  .jumbotron .small {
    font-size: 1.1em; }

.jumbotronPadded {
  padding: 60px 0 70px 0; }

.nav-pills {
  background: #079dff;
  color: #FFF; }
  .nav-pills li a {
    color: #FFF; }
  .nav-pills li a:hover {
    background: #0061A0; }

.topic .panel-body p a {
  color: #34699a; }

form[name="contactForm"] input, form[name="contactForm"] textarea, form[name="feedbackForm"] input, form[name="feedbackForm"] textarea {
  margin: 10px 0; }
