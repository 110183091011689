.navbar-default .navbar-nav > li > a.btn-navbar,
.btn-navbar {
  background: #0061A0;
  color: #FFF; }
  .navbar-default .navbar-nav > li > a.btn-navbar:hover,
  .btn-navbar:hover {
    color: #ED8C01; }
  .navbar-default .navbar-nav > li > a.btn-navbar:focus,
  .btn-navbar:focus {
    color: #FFF; }

.btn-navbar:hover,
.btn-navbar:active,
.btn-navbar:visited {
  background: #0061A0;
  color: #FFF; }

.typeaheadWrapper {
  width: 100%; }
  .typeaheadWrapper a {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box; }

.top-bar {
  background-color: #ED8C01;
  color: #FFF;
  padding: 3px;
  position: relative;
  z-index: 1010; }
  @media (max-width: 1000px) {
    .top-bar {
      font-size: 12px; } }
  .top-bar ul.list-inline {
    margin-bottom: 0; }
  .top-bar a {
    color: #FFF;
    display: inline-block;
    padding: 5px; }
    .top-bar a i {
      margin-right: 0.25em; }

header {
  position: relative;
  padding-top: 0px;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: contain !important;
  background-color: #FFF !important; }
  header #logo-lg img {
    margin: 0 auto;
    max-width: 240px; }
  header .search, header .links {
    z-index: 800;
    position: relative;
    top: 15px; }
    header .search .department, header .links .department {
      background: #0061A0;
      padding-right: 40px;
      padding-left: 40px;
      border: solid 1px #0061A0; }
    header .search input, header .links input {
      border: 1px solid #b9bec1;
      background: #b9bec1;
      color: #FFF; }
    header .search ::-webkit-input-placeholder, header .links ::-webkit-input-placeholder {
      color: #FFF; }
    header .search ::-moz-placeholder, header .links ::-moz-placeholder {
      color: #FFF; }
    header .search :-ms-input-placeholder, header .links :-ms-input-placeholder {
      color: #FFF; }
    header .search :-moz-placeholder, header .links :-moz-placeholder {
      color: #FFF; }
  header .nav li a {
    padding: 10px 0 !important;
    background-color: #FFF !important; }

@media (max-width: 760px) {
  nav nav.navbar {
    border-top: 5px solid #ED8C01; } }

nav {
  z-index: 1000; }
  nav li a {
    text-align: left !important; }
  nav li a:hover {
    background: transparent !important; }
  nav .dropdown-departments.open,
  nav .dropdown-departments:hover {
    position: static; }
  nav .dropdown-departments.open .dropdown-menu {
    display: table;
    width: 80%;
    left: 30px;
    padding: 0; }
  nav .dropdown-departments.inset .dropdown-menu {
    left: 150px;
    width: 135%; }
  nav .dropdown-departments.inset-small .dropdown-menu {
    left: 150px;
    width: 100%; }
  nav .dropdown-departments-menu {
    padding: 0;
    background: white; }
    nav .dropdown-departments-menu li {
      padding: 6px 10px; }
    nav .dropdown-departments-menu .highlight {
      background: #FFF; }
  nav .dropdown-departments-content {
    padding: 15px 10px; }
    nav .dropdown-departments-content .title {
      width: 100%;
      margin-top: 0;
      padding-bottom: 10px;
      border-bottom: solid 1px #ccc;
      font-size: 1.6em; }
    nav .dropdown-departments-content .category-list {
      list-style-type: none;
      padding: 0; }
      nav .dropdown-departments-content .category-list li {
        transition: .2s;
        padding: 1px 5px;
        border-radius: 2px;
        background: #FFF; }
      nav .dropdown-departments-content .category-list li:hover {
        background: white; }
  nav .nav-search {
    -webkit-transition: width  .5s ease-in-out;
    -moz-transition: width  .5s ease-in-out;
    -o-transition: width  .5s ease-in-out;
    transition: width  .5s ease-in-out; }
    nav .nav-search .department {
      border: none; }
    nav .nav-search #navSearchFilter .dropdown-menu {
      left: 0;
      right: auto; }
    nav .nav-search input {
      -webkit-transition: width  1s ease-in-out;
      -moz-transition: width  1s ease-in-out;
      -o-transition: width  1s ease-in-out;
      transition: width  1s ease-in-out;
      width: 200px !important;
      border: none !important;
      background: #b9bec1;
      color: #FFF; }
    nav .nav-search ::-webkit-input-placeholder {
      color: #FFF; }
    nav .nav-search ::-moz-placeholder {
      color: #FFF; }
    nav .nav-search :-ms-input-placeholder {
      color: #FFF; }
    nav .nav-search :-moz-placeholder {
      color: #FFF; }
  nav .nav-search-active {
    width: 100% !important; }
    nav .nav-search-active input {
      background: #FFF !important;
      color: #0061A0; }
  nav .smallSearch {
    padding: 10px 0; }
  nav .cancel-search i {
    color: #9ABFD9; }

.nav-small {
  font-size: .9em; }

.mobile-nav {
  background: #FFF;
  width: 108%;
  margin-left: -4%; }
  .mobile-nav a {
    background: #FFF;
    border: solid 1px #FFF;
    color: #0061A0;
    border-radius: 0;
    font-size: .95em; }
  .mobile-nav a:hover,
  .mobile-nav a:active,
  .mobile-nav a:visited {
    background: #FFF;
    border: solid 1px #ccc;
    color: #0061A0; }

.mobile-search {
  left: 0;
  background: #FFF;
  padding: 5px 0; }
  .mobile-search .mobile-search-wrapper {
    padding: 0 11px 0 20px; }
    .mobile-search .mobile-search-wrapper input {
      background: #b9bec1;
      transition: .4s;
      border: none;
      color: #FFF; }
    .mobile-search .mobile-search-wrapper ::-webkit-input-placeholder {
      color: #FFF; }
    .mobile-search .mobile-search-wrapper ::-moz-placeholder {
      color: #FFF; }
    .mobile-search .mobile-search-wrapper :-ms-input-placeholder {
      color: #FFF; }
    .mobile-search .mobile-search-wrapper :-moz-placeholder {
      color: #FFF; }

#header-nav-wrap {
  position: relative; }

.cart-overlay-fixed {
  position: fixed; }

.cart-overlay-absolute {
  position: absolute; }

.cart-overlay {
  top: 0;
  background-color: #FFF;
  border: 1px solid #0061A0;
  right: 0;
  width: 220px;
  z-index: 1000;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2); }

#cart-overlay-overview,
#cart-overlay-details {
  padding: 0.5em; }

.cart-overlay-items {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto; }

#cart-overlay-overview {
  background-color: #0061A0;
  color: #FFF;
  text-align: center; }
  #cart-overlay-overview .btn {
    margin: 0.5em 0; }

#cart-overlay-details {
  min-height: 10vh; }
  #cart-overlay-details .no-items {
    padding-top: 0.75em;
    text-align: center; }
  #cart-overlay-details ul, #cart-overlay-details li {
    list-style: none;
    margin: 0;
    padding: 0; }
  #cart-overlay-details li {
    margin: 1.5em 0; }
  #cart-overlay-details li .view-default {
    padding-bottom: 1em; }
  #cart-overlay-details li .view-default:after {
    content: " ";
    display: table;
    clear: both; }
  #cart-overlay-details li .view-default .img-wrap-default {
    float: left;
    width: 50%; }
    #cart-overlay-details li .view-default .img-wrap-default img {
      padding-left: 0.5em;
      max-height: 70px;
      max-width: 100%; }
  #cart-overlay-details li .view-default .data-wrap-default {
    float: left;
    padding-left: 1.5em; }
    #cart-overlay-details li .view-default .data-wrap-default span.item-price {
      color: #0061A0; }
  #cart-overlay-details li .view-hover {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 0.5em;
    padding-bottom: 1em;
    padding-top: 1em; }
  #cart-overlay-details li .view-hover img {
    float: left;
    padding-left: 0.5em;
    padding-top: 0.5em;
    max-width: 33%;
    max-height: 110px; }
  #cart-overlay-details li .view-hover .item-info {
    float: left;
    margin-bottom: 1em;
    padding-left: 0.33em;
    width: 60%;
    min-height: 100px; }
    #cart-overlay-details li .view-hover .item-info .item-price {
      clear: left;
      display: block;
      color: #0061A0; }
  #cart-overlay-details li:first-of-type .view-hover {
    border-top: 0;
    padding-top: 0; }
  #cart-overlay-details li:last-of-type {
    margin-bottom: 0.5em; }
    #cart-overlay-details li:last-of-type .view-default {
      padding-bottom: 0; }
    #cart-overlay-details li:last-of-type .view-hover {
      border-bottom: 0;
      margin-bottom: 0; }
  #cart-overlay-details a.item-delete {
    color: #888;
    display: inline-block;
    margin-left: 0.25em; }
    #cart-overlay-details a.item-delete:hover {
      color: #0061A0; }

#servicesSolutionsList:hover #services-and-solutions-overlay, #currentUser:hover #services-and-solutions-overlay {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s; }

#services-and-solutions-overlay:hover {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s; }

#services-and-solutions-overlay {
  position: relative;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .1s all;
  -webkit-transition-delay: .1s;
  -moz-transition: .1s all;
  -moz-transition-delay: .1s;
  -ms-transition: .05s all;
  -ms-transition-delay: .1s;
  -o-transition: .1s all;
  -o-transition-delay: .1s;
  transition: .1s all;
  transition-delay: .1s;
  bottom: 0;
  z-index: 1000;
  margin-bottom: -500%;
  float: left;
  background-color: #FFF;
  border: 1px solid #ED8C01;
  padding: 1em;
  color: black;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2); }
  #services-and-solutions-overlay h2 {
    font-weight: bold;
    font-size: 14px;
    margin: 0 0 2px;
    padding: 0;
    color: #0061A0;
    border-bottom: solid #ED8C01 1px; }
  #services-and-solutions-overlay ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px; }
    #services-and-solutions-overlay ul li {
      list-style: none; }
    #services-and-solutions-overlay ul a {
      color: #0061A0;
      padding: 0; }
      #services-and-solutions-overlay ul a:hover {
        color: #079dff; }

#services-and-solutions-overlay {
  width: 145%;
  top: 45px;
  left: -20%;
  margin-right: -250px; }

#services-and-solutions-overlay:after,
#services-and-solutions-overlay:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

#services-and-solutions-overlay:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFF;
  border-width: 15px;
  margin-left: -15px; }

#services-and-solutions-overlay:before {
  border-color: rgba(237, 140, 1, 0);
  border-bottom-color: #ED8C01;
  border-width: 16px;
  margin-left: -16px; }

#search-results-overlay {
  position: relative;
  display: inline-block;
  top: 15px;
  left: 1%;
  width: 98%;
  right: 1%;
  bottom: 0;
  z-index: 1000;
  background-color: #FFF;
  border: 1px solid #ED8C01;
  padding: 1em 0;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2); }
  @media (min-width: 1200px) {
    #search-results-overlay {
      top: -60px; } }
  #search-results-overlay h3 {
    font-size: 1.15em; }
  #search-results-overlay ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 340px; }
    #search-results-overlay ul li {
      list-style: none;
      padding: 0 0 0.5em 0; }
    #search-results-overlay ul.algolia-products li {
      height: 70px;
      padding: 0; }
    #search-results-overlay ul .prod-img {
      display: block;
      float: left;
      text-align: center;
      width: 90px; }
    #search-results-overlay ul img {
      max-width: 80px;
      max-height: 50px;
      margin: 0 8px; }
    #search-results-overlay ul .prod-info {
      display: block;
      float: left;
      margin-left: 10px;
      margin-top: 0.25em;
      width: 250px; }
    #search-results-overlay ul a {
      color: #0061A0; }
      #search-results-overlay ul a:hover {
        color: #079dff; }
  #search-results-overlay .more-info {
    margin-top: 25px;
    font-size: 0.7em; }
    #search-results-overlay .more-info i {
      font-size: .66em; }

#search-results-overlay:after,
#search-results-overlay:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

#search-results-overlay:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFF;
  border-width: 15px;
  margin-left: -15px; }

#search-results-overlay:before {
  border-color: rgba(237, 140, 1, 0);
  border-bottom-color: #ED8C01;
  border-width: 16px;
  margin-left: -16px; }

.one-edge-shadow {
  border-bottom: 1px solid #eee;
  -webkit-box-shadow: 0 2px 16px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 16px 0px rgba(0, 0, 0, 0.1); }

.header-desktop {
  display: none;
  padding-bottom: 40px;
  border-top: 18px solid #FFF; }

@media (max-width: 992px) {
  .navbar-nav-button {
    padding: 12px !important; } }

@media (min-width: 1200px) {
  .header-desktop {
    display: block; } }

.tablet-search {
  margin-left: -40px; }

.search-overlay-fixed {
  position: fixed !important;
  top: 68px !important; }
  @media (min-width: 1024px) {
    .search-overlay-fixed {
      max-height: 60%; } }

#companyViewDrop {
  padding: .75em;
  position: relative;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  z-index: 825 !important;
  background: #0061A0; }
  #companyViewDrop .container-fluid #customerSearch {
    position: relative; }
    #companyViewDrop .container-fluid #customerSearch label {
      display: inline-block;
      color: #FFF;
      padding: 0 5px; }
    #companyViewDrop .container-fluid #customerSearch #inputGroup {
      display: inline-block; }
      #companyViewDrop .container-fluid #customerSearch #inputGroup input {
        position: relative;
        width: 350px; }
      #companyViewDrop .container-fluid #customerSearch #inputGroup #customerResults {
        position: absolute;
        width: 352px;
        background: #FFF;
        z-index: 851;
        border: 2px #0061A0 solid;
        border-top: none;
        max-height: 315px;
        overflow-y: auto; }
        #companyViewDrop .container-fluid #customerSearch #inputGroup #customerResults .resultRow {
          display: block;
          padding: 5px 10px;
          text-align: left; }
    #companyViewDrop .container-fluid #customerSearch .shipToSelect {
      margin-left: 10px; }
    #companyViewDrop .container-fluid #customerSearch .setCompanyBtn {
      margin-left: 10px; }
  #companyViewDrop .container-fluid .customerEditBtn {
    padding: 4px 10px 4px 10px;
    font-size: 15px;
    border-radius: 5px;
    color: white; }
  #companyViewDrop .container-fluid .nowViewingAs {
    color: #FFF;
    vertical-align: middle;
    display: inline-block; }
    #companyViewDrop .container-fluid .nowViewingAs span {
      font-size: 20px;
      padding: 0 .2em; }

#companyViewDrop.hideDrop {
  display: none; }
